
import mediclubProductLoader from '~/helpers/mediclub3productLoader'
import keepAliveScrollMixin from '~/mixins/keepAliveScroll'

export default {
  // eslint-disable-next-line vue/match-component-file-name
  name: 'Mediclub',

  mixins: [
    keepAliveScrollMixin,
  ],

  async asyncData ({ app, store }) {
    await store.dispatch('staticBlock/getByIdentifiers', [
      'mediclub-block-v3-1',
      'mediclub-block-v3-2',
      'mediclub-block-v3-3',
      'mediclub-block-v3-4',
      'mediclub-block-v3-5',
      'mediclub-block-v3-survey',
      'mediclub-block-v3-7',
      'mediclub-block-v3-8',
      'mediclub-block-v3-9',
      'mediclub-block-v3-10',
      'mediclub-block-v3-faq',
      'mediclub-block-v3-menu',
    ])

    const { limitedBenefitItems, activeIndex } = await mediclubProductLoader({ app, store }, 'mediclub-block-v3-5')

    store.dispatch('breadcrumbs/setBreadcrumbs', { name: 'MediClub' })

    return {
      activeIndex,
      limitedBenefitItems,
    }
  },
}
